// MatrixRain.js
import React, { useRef, useEffect } from 'react';
import './MatrixRain.css';

const MatrixRain = () => {
  const canvasRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Adjust canvas size on window resize
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const fontSize = 14;
    const columns = canvas.width / fontSize;
    const drops = Array(Math.floor(columns)).fill(1);

    // Character set for Matrix effect
    const matrixChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let startTime = Date.now();
    const PHASE_DURATION = 18000; // 18 seconds per phase
    const TRANSITION_DURATION = 6000; // 6-second crossfade

    // WarGames specific elements
    const warGamesText = [
      'WOULD YOU LIKE TO PLAY A GAME?',
      'GLOBAL THERMONUCLEAR WAR',
      'DEFCON 4',
      'MISSILE COMMAND ACTIVE',
      'TRACKING TARGETS...',
      'USSR ICBMs DETECTED: 47',
      'US DEFENSE SYSTEMS: ACTIVE'
    ];
    let typeIndex = 0;
    let charIndex = 0;
    let lastTypeTime = 0;

    // Missile data for WarGames effect
    let missiles = [];
    let explosions = []; // For explosion effects
    let radarImpacts = []; // For radar pulse effects

    const drawWarGames = (ctx, opacity) => {
      ctx.save();
      ctx.globalAlpha = opacity;

      // Draw grid background
      ctx.strokeStyle = 'rgba(0, 255, 0, 0.1)';
      ctx.lineWidth = 1;
      const gridSize = 40;

      for (let x = 0; x < canvas.width; x += gridSize) {
        for (let y = 0; y < canvas.height; y += gridSize) {
          ctx.strokeRect(x, y, gridSize, gridSize);
          // Reduce flickering numbers and their frequency
          if (Math.random() < 0.0005) {
            ctx.fillText(
              `${Math.floor(x / gridSize)},${Math.floor(y / gridSize)}`,
              x + 5,
              y + 14
            );
          }
        }
      }

      // Typing effect for main text
      const currentTime = Date.now();
      if (currentTime - lastTypeTime > 200) {
        charIndex++;
        lastTypeTime = currentTime;
        if (charIndex > warGamesText[typeIndex].length) {
          if (currentTime - lastTypeTime > 4000) {
            typeIndex = (typeIndex + 1) % warGamesText.length;
            charIndex = 0;
          }
        }
      }

      // Draw current typing text
      ctx.fillStyle = '#00ff00';
      ctx.font = '20px Courier';
      ctx.fillText(
        warGamesText[typeIndex].substring(0, charIndex),
        canvas.width / 2 - 200,
        canvas.height / 2
      );

      // Draw status information
      ctx.font = '14px Courier';
      ctx.fillText(`DEFCON STATUS: ${Math.floor(Date.now() / 1000) % 5 + 1}`, 20, 30);
      ctx.fillText(`LAT: 38°51'N  LON: 77°02'W`, 20, 50);
      ctx.fillText(`NORAD CMOC STATUS: ACTIVE`, 20, 70);

      // Launch missiles more frequently
      if (Math.random() < 0.05) {
        missiles.push({
          x: Math.random() * canvas.width,
          y: canvas.height,
          targetX: Math.random() * canvas.width,
          targetY: 0,
          trail: []
        });
      }

      // Draw missiles with tracers
      ctx.strokeStyle = '#00ff00'; // Green color for missiles
      missiles.forEach((missile, index) => {
        // Calculate missile position
        const dx = missile.targetX - missile.x;
        const dy = missile.targetY - missile.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const speed = 2;
        missile.x += (dx / dist) * speed;
        missile.y += (dy / dist) * speed;

        // Store trail positions
        missile.trail.push({ x: missile.x, y: missile.y });
        if (missile.trail.length > 10) {
          missile.trail.shift();
        }

        // Draw trail
        ctx.beginPath();
        ctx.moveTo(missile.trail[0].x, missile.trail[0].y);
        missile.trail.forEach((pos) => {
          ctx.lineTo(pos.x, pos.y);
        });
        ctx.stroke();

        // Remove missile if it reaches the target
        if (missile.y <= missile.targetY) {
          // Add explosion at target
          explosions.push({
            x: missile.targetX,
            y: missile.targetY,
            radius: 0,
            maxRadius: 50, // Increased explosion size
            alpha: 1
          });
          missiles.splice(index, 1);
        }
      });

      // Draw explosions
      explosions.forEach((explosion, index) => {
        ctx.beginPath();
        ctx.arc(explosion.x, explosion.y, explosion.radius, 0, 2 * Math.PI);
        ctx.fillStyle = `rgba(0, 255, 0, ${explosion.alpha})`;
        ctx.fill();

        // Update explosion
        explosion.radius += 1; // Increased expansion speed
        explosion.alpha -= 0.01;

        // Remove explosion when it's done
        if (explosion.alpha <= 0) {
          explosions.splice(index, 1);
        }
      });

      // Create more radar impacts
      if (Math.random() < 0.01) {
        radarImpacts.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: 0,
          maxRadius: 200,
          alpha: 0.5
        });
      }

      // Draw radar impacts
      radarImpacts.forEach((impact, index) => {
        ctx.beginPath();
        ctx.arc(impact.x, impact.y, impact.radius, 0, 2 * Math.PI);
        ctx.strokeStyle = `rgba(0, 255, 0, ${impact.alpha})`;
        ctx.lineWidth = 2;
        ctx.stroke();

        // Update impact
        impact.radius += 2;
        impact.alpha -= 0.005;

        // Remove impact when it fades out
        if (impact.alpha <= 0) {
          radarImpacts.splice(index, 1);
        }
      });

      ctx.restore();
    };

    // Add this near other state variables at the top of useEffect
    const nezPerceArt = []; // We'll populate this with the ASCII art data
    let nezPerceCharsRevealed = 0;
    let lastCharRevealTime = 0;

    // Replace the entire drawSpaceInvaders function with this new one
    const drawNezPerce = (ctx, phaseElapsed, opacity) => {
      // Control iframe visibility based on phase
      const iframe = iframeRef.current;
      if (!iframe) return;

      if (phaseElapsed < TRANSITION_DURATION) {
        // Fade in
        iframe.style.opacity = (phaseElapsed / TRANSITION_DURATION) * opacity;
      } else if (phaseElapsed < PHASE_DURATION) {
        // Hold
        iframe.style.opacity = opacity;
      } else {
        // Fade out
        iframe.style.opacity = (1 - (phaseElapsed - PHASE_DURATION) / TRANSITION_DURATION) * opacity;
      }
    };

    const drawMatrix = (ctx, opacity) => {
      ctx.save();
      ctx.globalAlpha = opacity;
      for (let i = 0; i < drops.length; i++) {
        const char = matrixChars[Math.floor(Math.random() * matrixChars.length)];
        ctx.fillText(char, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
      ctx.restore();
    };

    const draw = () => {
      const currentTime = Date.now();
      const totalCycle = (PHASE_DURATION + TRANSITION_DURATION) * 3; // 72 seconds
      const elapsed = (currentTime - startTime) % totalCycle;
      const phaseTime = PHASE_DURATION + TRANSITION_DURATION;

      let currentPhase, nextPhase;
      let phaseElapsed;
      let transitionProgress = 0;

      if (elapsed < phaseTime) {
        // First phase: Matrix
        phaseElapsed = elapsed;
        currentPhase = 'matrix';
        nextPhase = 'wargames';
      } else if (elapsed < phaseTime * 2) {
        // Second phase: WarGames
        phaseElapsed = elapsed - phaseTime;
        currentPhase = 'wargames';
        nextPhase = 'nezperce';
      } else {
        // Third phase: Nez Perce
        phaseElapsed = elapsed - phaseTime * 2;
        currentPhase = 'nezperce';
        nextPhase = 'matrix';
      }

      if (phaseElapsed < PHASE_DURATION) {
        // Hold period
        transitionProgress = 0;
      } else {
        // Transition period
        transitionProgress = (phaseElapsed - PHASE_DURATION) / TRANSITION_DURATION;
      }

      // Clear canvas with adjusted opacity
      ctx.fillStyle = `rgba(26, 26, 26, 0.1)`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#00ff00';
      ctx.font = `${fontSize}px Courier`;

      // Adjust opacity based on transition progress (min 0.1, max 0.6)
      const opacityRange = 0.6 - 0.1; // 0.5
      const currentOpacity = 0.1 + (1 - transitionProgress) * opacityRange;
      const nextOpacity = 0.1 + transitionProgress * opacityRange;

      // Draw current phase
      if (currentPhase === 'matrix') {
        drawMatrix(ctx, currentOpacity);
      } else if (currentPhase === 'wargames') {
        drawWarGames(ctx, currentOpacity);
      } else if (currentPhase === 'nezperce') {
        drawNezPerce(ctx, phaseElapsed, currentOpacity);
      }

      // Draw next phase during transition
      if (transitionProgress > 0) {
        if (nextPhase === 'matrix') {
          drawMatrix(ctx, nextOpacity);
        } else if (nextPhase === 'wargames') {
          drawWarGames(ctx, nextOpacity);
        } else if (nextPhase === 'nezperce') {
          drawNezPerce(ctx, 0, nextOpacity); // Start from beginning
        }
      }
    };

    const animationId = setInterval(draw, 50);
    return () => {
      clearInterval(animationId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <>
      <canvas ref={canvasRef} className="matrix-canvas fade-cycle"></canvas>
      <iframe 
        ref={iframeRef}
        src="/nezPerce.html" 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          pointerEvents: 'none',
          zIndex: -1,
          opacity: 0
        }}
      />
    </>
  );
};

export default MatrixRain;
