// Projects.js
import React, { useEffect, useState } from 'react';
import './Projects.css';

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('/projects.json')
      .then((response) => response.json())
      .then((data) => {
        const sortedProjects = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setProjects(sortedProjects);
      })
      .catch((error) => console.error('Error loading projects:', error));
  }, []);

  return (
    <section className="projects">
      <h3>Projects & Roles</h3>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-item">
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              {project.title}
            </a>
            <div className="project-role">{project.role}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
